<script setup lang="ts">
    import type { BlogHeaderData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    const appStore = useAppStore();
    const pageData = appStore.pageCache;

    /**
     * BlogHeader widget - displays a title and optional subtitle and image underneath the title.
     * uses the base data of the page as fallback
     */
    const props = defineProps<IWidgetProps<BlogHeaderData>>();
    const { data, resolveTag, resolveAuthor } = useWidget(props);
    const { d } = useLocales();

    /**
     * title or fallback title from page
     */
    const title = computed(() => {
        // console.log('fallback page data', pageData?.page?.base);
        if (data.title) {
            return data.title;
        }
        return pageData?.page?.base?.title;
    });
    const subtitle = computed(() => {
        if (data.subtitle) {
            return data.subtitle;
        }
        return pageData?.page?.base?.description;
    });
    const hero = computed(() => {
        if (data.hero) {
            return data.hero;
        }
        return pageData?.page?.base?.hero;
    });
    const tag = computed(() => {
        if (pageData?.page?.base?.tag_ref) {
            return resolveTag(pageData.page.base.tag_ref, appStore.getReference);
        }
        return undefined;
    });
    const date = computed(() => {
        if (pageData?.page?.base?.date) {
            const date = new Date(pageData.page.base.date);
            return d(date);
        }
        return undefined;
    });
    const author = computed(() => {
        if (pageData?.page?.base?.author_ref) {
            return resolveAuthor(pageData.page.base.author_ref, appStore.getReference);
        }
        return undefined;
    });
</script>
<template>
    <mol-blog-header
        :title="title"
        :subtitle="subtitle"
        :hero="hero"
        :tag="tag"
        :author="author"
        :date="date"
        class="cms-blog-header" />
</template>
